<template>
  <picture ref="picture" class="block overflow-hidden" :style="{ background: props.background }">
    <source v-if="srcWebp" :data-srcset="srcWebp" type="image/webp" />
    <img
      class="lazyload zoomTo"
      :class="[lazyPreload ? 'lazypreload' : null, imgClass]"
      :data-expand="expand"
      :data-sizes="sizes"
      :data-srcset="src"
      :sizes="sizes"
      :src="
        placeholder
          ? placeholder
          : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
      "
      :width="width"
      :height="height"
      :alt="alt"
    />
  </picture>
</template>

<script setup lang="ts">
interface Props {
  background?: string
  expand?: number
  lazyPreload?: boolean
  sizes?: string
  src: string | undefined
  srcWebp?: string
  placeholder?: string
  width: number
  height: number
  alt?: string | null
  imgClass?: string | null
}

const props = withDefaults(defineProps<Props>(), {
  background: 'transparent',
  expand: -50,
  lazyPreload: false,
  sizes: 'auto',
  alt: '',
  imgClass: null
})

// eslint-disable-next-line
const emit = defineEmits<{
  (event: 'loaded', e: any): void
  (event: 'beforeunveil', e: any): void
}>()

const isUnveiled = ref(false)
const isLoaded = ref(false)

onMounted(() => {
  if (document) {
    document.addEventListener('lazyloaded', (e) => onLoaded(e))
    document.addEventListener('lazybeforeunveil', (e) => onUnveil(e))
  }
})

function onLoaded(e) {
  if (!isLoaded.value) {
    isLoaded.value = true
    emit('loaded', e)
    // console.log('lazyloaded event:', e.target)
  }
}

function onUnveil(e) {
  if (!isUnveiled.value) {
    isUnveiled.value = true
    emit('beforeunveil', e)
    // console.log('lazybeforeunveil event:', e)
  }
}

onUnmounted(() => {
  if (document) {
    document.removeEventListener('lazyLoaded', onLoaded)
    document.removeEventListener('lazybeforeunveil', onUnveil)
  }
})
</script>

<style lang="postcss">
.zoomTo {
  will-change: opacity, transform;
  opacity: 0;
  transform: scale(1.16);
  transition:
    opacity 400ms linear,
    transform 800ms var(--ease-out-quart);
}

.lazyloaded {
  opacity: 1;
  transform: scale(1);
}
</style>
